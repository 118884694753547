$primary: #00647b;
$secondary: #d0e3e9;
$tertiary: #45567d;
$neutral1: #ffffff;
$neutral2: #f9fcfd;
$neutral3: #ecf2f6;

// // This is where we modify Mobiscroll colors. For a full list of colors, see: https://docs.mobiscroll.com/theming#intro

$mbsc-ios-accent: $primary;
$mbsc-ios-background: $neutral1;
$mbsc-ios-text: $tertiary;

@import '~@mobiscroll/react/dist/css/mobiscroll.react.min.css';

.mbsc-ios.mbsc-calendar-cell {
  border: none;
  /* border-right: none; */
  /* border-left: none; */
  background: #ffffff !important;
}

.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-header {
  border-bottom: none;
  background: transparent;
}
.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-wrapper,
.mbsc-ios.mbsc-list-item {
  border: none;
  background: transparent;
}

/* When there are no events, hide the empty state */
.mbsc-event-list-empty {
  display: none;
}

.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-wrapper {
  border-bottom: none !important;
}

.mbsc-ios.mbsc-calendar-cell {
  border: none !important;
}

.mbsc-hb,
.mbsc-hb:before,
.mbsc-hb:after {
  border: none !important;
}

.mbsc-ios.mbsc-list-item {
  padding: 0 !important;
}

.mbsc-ios.mbsc-schedule-date-header {
  background: $neutral2;
}

/** Ionic Components */
ion-toolbar {
  --background: #f9fcfd;
  --border-color: #ecf2f6 !important;
  --border-width: 2px !important;
}

ion-tab-bar {
  --background: #f9fcfd;
  --border: 2px solid #ecf2f6 !important;
  border-top: 2px solid #ecf2f6 !important;
}

ion-content.neutral-3 {
  --background: #ecf2f6 !important;
}

ion-content.neutral-2 {
  --background: #f9fcfd !important;
}
