.routine-list-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title-container {
  display: flex;
  align-items: center;
  gap: 4px;
}
