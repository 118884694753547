@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: light only;
  --primary-navy: #45567d;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Color for the input slider */
  accent-color: var(--primary-navy);
  background-color: #ecf2f6 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Prime button styles */
.p-button {
  font-weight: 600;
  width: 100%;
  line-height: 1;
}

/** handles tabs on the caretaker routines page */
.p-tabview .p-tabview-panels {
  padding: 0 !important;
}
