/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #00647b;
  --ion-color-primary-rgb: 0, 100, 123;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00586c;
  --ion-color-primary-tint: #1a7488;

  --ion-color-secondary: #d0e3e9;
  --ion-color-secondary-rgb: 208, 227, 233;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #b7c8cd;
  --ion-color-secondary-tint: #d5e6eb;

  --ion-color-tertiary: #45567d;
  --ion-color-tertiary-rgb: 69, 86, 125;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #3d4c6e;
  --ion-color-tertiary-tint: #58678a;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #ecf2f6;
  --ion-color-medium-rgb: 236, 242, 246;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #d0d5d8;
  --ion-color-medium-tint: #eef3f7;

  --ion-color-light: #f9fcfd;
  --ion-color-light-rgb: 249, 252, 253;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dbdedf;
  --ion-color-light-tint: #fafcfd;

  --ion-grid-width: 600px;
  --ion-grid-padding: 0px;
  --ion-background-color: #ecf2f6;
  --ion-text-color: #45567d;
}
